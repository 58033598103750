/* 
td{
    background-color: #F3F3F3;
} */

thead {
  background-color: #e4f1f8;
  color: #1b2124;
}
/* 
.submit-button{
    background-color: #1489C8;
    color: #ffffff;
    border: 1px solid #1489C8 ;
    border-radius: 10px;
} */

.modal-body {
  background-color: #e4f1f8;
}

.add-agent-icon {
  float: right;
  margin-bottom: 10px;
  font-size: 20px;
  cursor: pointer;
}

.card-icon {
  margin-left: 10px;
}

.agent-icon,
.export-icon,
.card-icon,
.check-icon,
.close-icon {
  font-size: 20px;
  cursor: pointer;
}

.agent-icon:hover,
.export-icon:hover,
.card-icon:hover,
.check-icon:hover,
.close-icon:hover {
  color: #1489c8;
}
