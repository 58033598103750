.opacity-blend {
  opacity: 0.7;
}

.form {
  margin-top: 10px;
  margin-bottom: 50px;
}

label {
  font-weight: 500;
}

.nav-link {
  color: #007bff;
}
