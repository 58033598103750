.messageMetaData {
  margin: 0px 10px;
  font-size: 13;
  color: rgba(0, 0, 0, 0.54);
}

.inboxIcon {
  font-size: large;
  color: green;
}

.outboxIcon {
  font-size: large;
  color: blue;
}

/* .close-icon{
    position:absolute;
    font-size: 20px;
    right: 75%;
    float: right;
    cursor: pointer;
  } */

.messageGrid {
  height: auto;
  border-left: 1px solid black;
}

.messages-icon {
  font-size: 20px;
}

.messages-icon:hover {
  color: #1489c8;
}
