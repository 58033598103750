@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i');
@import url('https://fonts.googleapis.com/css?family=Poppins:100,300,400,500,700,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap');

body {
  font-family: 'Poppins', 'Open Sans', sans-serif;
  font-size: 100;
  /* background-color: #f2f6ff;*/
}

.nav-link.active {
  background-color: #0c80be;
  color: white;
}

.nav-link:hover {
  background-color: #0c80be;
  color: white;
}

/* .nav-link {
  color: white;
} */
.nav-link-sidemenu {
  color: white;
}

.nav-item {
  padding: 7px;
  text-align: center;
}

.pharmacy-card {
  cursor: pointer;
  border: 1px solid #eae3e3;
  min-height: 105px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.pharmacy-card:hover {
  box-shadow: 0 3px 9px 3px rgba(0, 0, 0, 0.2);
  background-color: #fdfdfd;
  cursor: pointer;
}

/*
 * Sidebar
 */

.sideshow {
  display: block;
  opacity: 1;
  transition: all 1.5s;
}

.sidehide {
  display: none;
  /* opacity: 0; */
  transition: all 1.5s;
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  /* margin-top: 20px; */
  background-color: transparent;
  margin-bottom: 20px;
  border-right: 1px solid #eee;
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  background-color: #1489c8;
  color: white;
  border-right: 1px solid #eee;
}

.right-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 100; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  background-color: transparent;
  margin-bottom: 20px;
  word-wrap: break-word;
  transition: all 0.5s;
}

.right-sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  padding: 1rem 2rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  background-color: #fff;
  color: #000;
  box-shadow: 0 8px 12px 0 rgba(19, 19, 19, 0.2);
  transition: all 0.5s;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link {
  font-weight: 300;
  color: #fff;
}

.sidebar .nav-link.active {
  color: #ffffff;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

/*
 * Navbar
 */

.navbar .navbar-toggler {
  /* top: 1rem; */
  left: 1rem;
  border-color: transparent;
}

.navbar-custom {
  height: 60px;
  background-color: #ffffff;
}

.wella-card {
  margin: 10px 0px;
  border-color: transparent;
  border-radius: 10px;
}

.wella-card:hover {
  box-shadow: 0 3px 9px 3px rgba(0, 0, 0, 0.2);
  background-color: #eef3f4;
  cursor: pointer;
}

.card-body {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 10px;
}

.table-striped tbody tr:nth-of-type(2n + 1) {
  background-color: #e5fae8;
}

/**/
.nav-dropdown {
  position: absolute !important;
  top: 70%;
  right: -20%;
  left: unset;
}

.nav-dropdown-toggle {
  display: inline;
}

.nav-dropdown-toggle:hover {
  background-color: #f8f9fa !important;
}

.custom-hamburger {
  top: 70% !important;
  left: 1%;
  border: 0px solid transparent;
}

.arrow-icon {
  float: right;
}

.btn-spinner {
  margin-top: 5px;
  float: right;
}

.table-spinner {
  margin-top: 5px;
  position: absolute;
}

.btn-close {
  float: right;
  margin-top: -10px;
}

/*Toast*/
.bg-info {
  background: #17a2b8 !important;
  color: #ffffff;
}

.bg-warn {
  background: #ffc107 !important;
  color: #000000;
}

.bg-error {
  background: #dc3545 !important;
  color: #ffffff;
}

.bg-success {
  background: #28a745 !important;
  color: #ffffff;
}

.bg-offline {
  background: #6c757d !important;
  color: #ffffff;
}

.toast-close {
  float: right;
}

/*spinner*/
.request-spinner {
  z-index: 1000;
  position: fixed;
  right: 3%;
  margin-top: 5px;
}

.fade-wrapper {
  display: none;
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
}

/*Css only modal: https://codepen.io/timothylong/pen/HhAer*/
.modal-window {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.25);
  top: 10%; /*0*/
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  /*visibility: visible;*/
  display: none;
  opacity: 1;
  pointer-events: auto;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.modal-window:target {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.modal-window > div {
  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 2em;
  background: #ffffff;
  border: 1px solid #1489c8;
}

.modal-window header {
  font-weight: bold;
}

/*.modal-window h1 {
		font-size: 150%;
		margin: 0 0 15px;
	}*/

.modal-close {
  color: #aaa;
  line-height: 50px;
  font-size: 80%;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 70px;
  text-decoration: none;
  cursor: pointer;
}

.modal-close:hover {
  color: black;
}

/*Modal ends*/

.react-bootstrap-table {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.prompt-icon {
  padding: 5px 14px;
  background-color: #2b8dff;
  border-radius: 15px;
  color: white;
  font-size: 1rem;
  display: inline-block;
  margin-top: 10px;
  margin-left: -12px;
  font-weight: bolder;
}

#install-banner {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #ffffff;
  color: #5ba532;
  position: fixed;
  z-index: 999;
  bottom: 0;
  width: 100%;
  font-size: 1rem;
}

#install-btn:focus {
  box-shadow: unset;
}

.center-section {
  box-shadow: 0 8px 12px 0 rgba(19, 19, 19, 0.2);
  background: white;
  padding: 20px;
}

.drop-down-item {
  padding: 0px 10px;
}
.drop-down-item:hover {
  background-color: #eeefff;
}

.bi-star-fill,
.bi-star {
  color: green;
  font-size: 2rem;
}

/* overrides */

.form-control:focus {
  border-color: #1489c8;
  box-shadow: unset;
}
.btn.focus,
.btn:focus {
  box-shadow: unset;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 0.7;
}

.dropdown-item {
  white-space: unset;
  font-size: 0.9rem;
  cursor: pointer;
}

.drop-down-menu-custom {
  width: 360px !important; /*300*/
  /* transform: translate(-124px, 34px) !important; */
}

.drop-down-menu-custom-1 {
  width: 200px !important;
}

/* media screen and  767px*/
@media (max-width: 767.98px) {
  .sidebar {
    top: 5rem;
  }

  .sidebar-sticky {
    margin-top: -80px !important;

    width: 40%;
  }

  .request-spinner {
    right: 5%;
  }
}

@media (max-width: 575.98px) {
  .modal-window > div {
    width: 360px;
  }

  .sidebar-sticky {
    /* margin-top: 40px !important; */
    width: 55%;
  }
}

/* New Styles */
input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button;
}

.bi {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.refresh-toast {
  float: right;
  padding: 50px;
  position: fixed;
  left: 50%;
  color: #191489;
  font-size: 1.3rem;
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);
  top: 50%;
  background-color: white;
  font-weight: 600;
  cursor: pointer;
  transform: translate(-50%, -50%);
}

.tariff-box {
  border: 1px solid black;
  padding: 15px 10px;
}

.close-icon,
.people-icon,
.add-icon {
  font-size: 25px;
}

.group-icon {
  font-size: 20px;
  margin-left: 10px;
  cursor: pointer;
}

.group-icon:hover {
  color: #1489c8;
}

strong {
  font-size: 25px;
}

.cancel-button {
  background-color: #cae4f2;
  color: #1489c8;
  border-radius: 10px;
  border: 1px solid #cae4f2;
}

.check-md {
  height: 18px;
  width: 18px;
}

#walking-distance {
  margin-top: 10px;
  border-top: 1px solid #e0e0e0;
  padding-top: 5px;
}

/* status colors */
.status-success {
  color: #28a745 !important;
}

.status-danger {
  color: #dc3545 !important;
}

.status-primary {
  color: #007bff !important;
}

.border-danger {
  border: 1px solid #dc3545;
}

/* font sizes */

.fs-7 {
  font-size: 0.9rem;
}

.fs-8 {
  font-size: 0.75rem;
}

.fs-9 {
  font-size: 0.6rem;
}

.fw-bold {
  font-weight: bold;
}

.logo-loader {
  position: fixed;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  background: #fdfdfd;
  opacity: 0.9;
  display: flex;
  align-items: center;
}

.logo-loader-img {
  animation: pulse 3s linear infinite;
  width: 128px;
  height: 128px;
  position: relative;
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.custom-textarea {
  white-space: pre-wrap;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1.1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1.1);
  }

  50% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1.1);
  }
}

/**** Patient Search Bar ****/

.search-result {
  padding: 4px;
  background: white;
  list-style: none;
}

.search-result:hover {
  color: #212529;
  /* background-color: rgba(0, 0, 0, 0.075); */
  cursor: pointer;
  cursor: pointer;
  background-color: #ddeef7;
}
.search-result-panel {
  position: absolute;
  width: 94%;
  height: auto;
  max-height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 1;
  border: 1px solid #0094ff;
  box-shadow: 0 0 10px #333333;
  background-color: white;
}

.search-icon {
  color: #59b32d;
  position: absolute;
  margin-top: 5px;
  left: 25px;
  cursor: pointer;
  padding: 2px 2px;
}

/* Has poor support in FF: https://caniuse.com/?search=has() */
label:has(+ input:required):after {
  content: ' *';
  color: red;
}

/* The dark side */

body.dark-theme {
  color: #eee;
  background-color: #4f4f4f;
}

body.dark-theme .table-hover tbody tr:hover {
  color: #eee;
  background: #717171;
}

body.dark-theme .table {
  color: #eee;
}

body.dark-theme thead {
  background-color: #e4f1f8;
}

body.dark-theme img {
  filter: brightness(0.8) contrast(1.2);
}

body.dark-theme .drop-down-menu-custom {
  color: #eee;
  background: #4f4f4f;
}

body.dark-theme .drop-down-item {
  color: #eee;
  background: #4f4f4f;
}

body.dark-theme .dropdown-item-custom {
  color: #eee;
}

body.dark-theme .dropdown-item-custom:hover {
  color: #4f4f4f;
}

body.dark-theme .sidebar-sticky {
  background-color: #4f4f4f;
}

body.dark-theme .navbar-custom {
  background-color: #bebebe;
}

body.dark-theme thead {
  background-color: #bebebe;
}

body.dark-theme select,
body.dark-theme input {
  color: #eee;
  background-color: #4f4f4f;
}

body.dark-theme .react-select__value-container {
  color: #eee;
  background-color: #4f4f4f;
}

body.dark-theme .react-select__menu {
  color: #eee;
  background-color: #4f4f4f;
}

body.dark-theme .react-select__menu:hover {
  color: #eee;
  background-color: #4f4f4f;
}

body.dark-theme .form-control:disabled,
body.dark-theme .form-control[readonly] {
  background-color: #5f7081;
  color: #fff;
}

body.dark-theme .modal-body,
body.dark-theme .modal-header,
body.dark-theme .modal-footer {
  background-color: #4f4f4f;
}

body.dark-theme .modal-dialog {
  border: 1px solid #fff;
}

body.dark-theme .custom-select:focus {
  background-color: #fff;
  color: #000;
}

body.dark-theme .react-select__single-value,
body.dark-theme .react-select__input {
  color: #fff;
}

body.dark-theme .react-select__option:hover {
  background-color: rgb(117, 114, 114);
}

body.dark-theme .tariff-box {
  border: 1px solid #fff;
}

body.dark-theme .card {
  background-color: transparent;
}

body.dark-theme .card-body {
  background-color: unset;
}

body.dark-theme .status-success {
  color: #7fdf95 !important;
}

body.dark-theme .status-danger {
  color: #f898a1 !important;
}

body.dark-theme .status-primary {
  color: #6ba6e5 !important;
}

body.dark-theme .search-result {
  background: #4f4f4f;
  color: #eee;
}

body.dark-theme .search-result:hover {
  color: #212529;
  background-color: #ddeef7;
}
body.dark-theme .search-result-panel {
  padding-left: unset;
  border: 1px solid #0094ff;
  box-shadow: 0 0 10px #333333;
  background-color: #4f4f4f;
}

/* End Dark Theme */
